<template>
    <div id="app_sign_sh_add_id">
        <!-- 上海移动直连-多媒体消息新增签名 -->
        <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" fullscreen destroy-on-close :show-close="true" :modal-append-to-body="false">
            <!-- 头 -->
            <el-row class="dialog_top">
                <span>多媒体消息新建签名</span>
                <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
            </el-row>

            <!-- 体 -->
            <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

                <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">
                    <el-form :model="form_data" label-width="120px" style="margin-bottom: 12px;">
                        <el-form-item v-show="form_data.is_send_channel == 1" label="签名id">
                            <el-input type="text" v-model="form_data.signword_id"
                                placeholder="请填写签名id" size="mini">
                            </el-input>
                        </el-form-item>

                        <el-form-item label="省份">
                            <el-select v-model="form_data.province" placeholder="请选择通道归属省份" size="mini" style="width:100%">
                                <el-option
                                    v-for="item in province_data"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>

                       <el-form-item label="地市">
                            <el-select v-model="form_data.city" placeholder="请选择通道归属地市" size="mini" style="width:100%">
                                <el-option
                                    v-for="item in city_data"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="服务代码">
                            <el-input v-model="form_data.service_code" placeholder="请输入服务代码（扩展后的码号）" size="mini" @focus="input_focus" type="number"></el-input>
                        </el-form-item>

                        <el-form-item label="签名内容">
                            <el-input v-model="form_data.signword" placeholder="请输入报备的签名内容" size="mini"></el-input>
                        </el-form-item>

                        <el-form-item label="实际发送企业">
                            <el-select v-model="form_data.user_company_id" placeholder="请从公司列表中选择实际发送的企业" size="mini" style="width:100%"> 
                                <el-option
                                    v-for="item in signCompanyList"
                                    :key="item.value"
                                    :label="item.name"
                                    :value="item.value"
                                    >
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="信息安全行业">
                            <el-checkbox-group v-model="form_data.rcs_industry" size="mini">
                                <el-checkbox v-for="item in rcs_industry_list" :label="item.id+''">{{item.content}}</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>

                        <el-form-item label="行业分类">
                            <el-select v-model="form_data.industry" placeholder="请选择签名所属的行业分类" size="mini" style="width:100%"> 
                                <el-option
                                    v-for="item in industry_list"
                                    :key="item.id"
                                    :label="item.content"
                                    :value="item.id"
                                    >
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="客户分类">
                            <el-select v-model="form_data.customer_type" placeholder="请选择客户分类" size="mini" style="width:100%"> 
                                <el-option
                                    v-for="item in customer_data"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="导入文件">
                            <!-- show-file-list:是否显示已上传文件列表  默认值 true
                            multiple:是否支持多选文件 
                            data:上传时附带的额外参数
                            auto-upload	是否在选取文件后立即进行上传
                            with-credentials 是否支持发送 cookie 凭证信息  true:支持
                            -->
                            <el-upload ref="uploadFile" style="display: inline-block;"
                                    :action="upload_url" :multiple="false" :limit="1" :auto-upload="false"
                                    :with-credentials="true"
                                    accept=".PNG,.png,.image/png,.PDF,.pdf"

                                    :on-success="uploadResponseResult"
                                    :on-error="uploadResponseResultFail"

                                    :file-list="fileList"
                                    
                                    :on-change="uploadChange"
                                    :on-remove="uploadChange"
                                    :before-upload="beforeAvatarUpload"
                                    >
                                <el-button size="mini" type="primary" plain @click="upload()">选取文件</el-button>
                                <div slot="tip" class="el-upload__tip">
                                    png格式或pdf格式文件，且上传文件大小限制最高2M
                                </div>
                            </el-upload>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" @click="onSubmit()" size="mini">保存</el-button>
                        </el-form-item>
                    </el-form>


                </el-row>

            </el-row>


        </el-dialog>
    </div>
</template>
<script>
import API from '../../api/api';
import {
    htmlDecode,
    getItem
} from '../../api/tool.js';

export default {
    props:{
        prop_change_i: 0,
        prop_channel_id: '',//通道id
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 
                setTimeout(() => {
                    //请求接口-获取签约公司列表
                    this.getSignCompanyList();
                }, 1);
            }
        }
    },

    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            channel_id : 0,//通道id

            //通道归属省份
            province_data: [{
                value: '上海',
                label: '上海'
            }],
            //通道归属地市
            city_data: [{
                value: '上海',
                label: '上海'
            }],

            signCompanyList:[],//签约公司列表

            form_data:{
                is_send_channel: "1",//是否直接向通道报备 0：是 1：不是
                signword_id:'',//签名id
                province:'',//通道归属省份
                city:'',//通道归属地市
                service_code:'',//服务代码
                signword:'',//签名内容
                user_company_id:'',//实际发送企业
                rcs_industry: [],//信息安全行业分类数据
                industry: '',//安全行业分类列表数据
                customer_type:'',//客户分类
            },

            rcs_industry_list: [],//信息安全行业分类数据
            industry_list: [],//安全行业分类列表数据

            //客户分类
            customer_data: [{
                value: '0',
                label: '直签客户'
            },{
                value: '1',
                label: '代理商'
            }],

            upload_url:"",//上传url
            fileList:[],//文件列表
           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();

    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            
            this.channel_id = this.$route.params.id;
        },
         //数据清除
        dataClear(){
            this.form_data = this.$options.data().form_data;//单个恢复初始化
            this.fileList = [];
        },
        //service_code：在 Input 获得焦点时触发
        input_focus(){
            if(this.form_data.service_code == "" || this.form_data.service_code == null){
                this.$set(this.form_data,'service_code','1069');
            }
        },
        //请求接口-获取签约公司列表
        getSignCompanyList(){
            API.CompanyServlet({
                param: "selList",
            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {
                    this.signCompanyList = res.list;//id + company_name
                    // var obj = {"id":"","company_name":"请选择"}
                    // this.signCompanyList.unshift(obj);//数组第一个位置插入元素

                    this.getRcsIndustry();
                }
            });
        },
        //请求接口-获取信息安全行业分类列表
        getRcsIndustry(){
            API.IndustrySignServlet({
                param: "rcsIndustry",
            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {
                    this.rcs_industry_list = res.list;//id + content

                    this.getIndustry();
                }
            });
        },
        //请求接口-获取信息安全行业分类列表
        getIndustry(){
            API.IndustrySignServlet({
                param: "industry",
            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {
                    this.industry_list = res.list;//id + content
                }
            });
        },
        //上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传。
        beforeAvatarUpload(file) {
            const imgType = file.name.endsWith('.png') || file.name.endsWith('.PNG')||file.name.endsWith('.pdf') || file.name.endsWith('.PDF');
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!imgType) {
                this.$message.error('上传文件只能是 png或pdf 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传文件大小不能超过 2MB!');
            }
            if(imgType && isLt2M){
                return new Promise((resolve, reject) => {
                    // 拼接上传url
                    // 此处动态配置action URL
                    this.upload_url = API.getInterfaceUrl()+"/MediaSignServlet?param=addMediaSignSH&session="+getItem("qxt_session")
                    +"&is_send_channel="+this.form_data.is_send_channel+"&signword_id="+this.form_data.signword_id
                    +"&channel_id="+this.channel_id+"&province="+this.form_data.province+"&city="+this.form_data.city
                    +"&service_code="+this.form_data.service_code+"&signword="+this.form_data.signword+"&user_company_id="+this.form_data.user_company_id
                    +"&rcs_industry="+this.form_data.rcs_industry+"&industry="+this.form_data.industry+"&customer_type="+this.form_data.customer_type
                    +"&callback="+( new Date().getTime()+ "_" + Math.ceil(Math.random() * 1000) );
                    // console.log(this.upload_url);
                    // dom上传地址更新完成后，触发上传
                    this.$nextTick(() => resolve());
                });
            }else{
                return imgType && isLt2M;
            }
        },
         //上传 - 文件上传或从列表中移除时触发 
        uploadChange(file, fileList){
            // console.log("文件上传或从列表中移除时触发 "+JSON.stringify(fileList));
            this.fileList = fileList;
        },

        //上传 按钮触发方法
        upload(){
            console.log("上传 按钮触发方法");
            if(this.$refs.uploadFile){
                this.$refs.uploadFile.clearFiles(); //上传成功之后清除历史记录
            }
            // this.upload_param:{},//上传带的额外参数
        },
        //上传成功响应结果
        uploadResponseResult(res, file, fileList) {
            //response这个
            // console.log("上传响应结果res:"+JSON.stringify(res));
            if(this.$refs.uploadFile){
                this.$refs.uploadFile.clearFiles(); //上传成功之后清除历史记录
            }
            if(res){
                // res = JSON.parse(res);
                if(res.recode == 0){
                    var duration = 2000;
                    if(API.isEmtry(res.text)){
                        res.text = "恭喜您，提交成功";
                    }else{
                        duration = 10000;
                    }
                    // this.$message.error(res.text,{duration:duration});
                    this.$message({
                        showClose: true,
                        message: res.text,
                        duration:duration,
                        type: 'success'
                    });
                    this.dialog_visible = false;//弹出框是否可见   false:不可见
                    this.$emit("add_sign", "");
                }else{
                    this.$message.error(res.text);
                }
            }else{
                 this.$message.error("文件上传失败，请稍后再试哦");
            }    
        },
        
        //上传失败响应结果
        uploadResponseResultFail(err, file, fileList){
            console.log("上传失败响应结果");
            this.$message.error("文件上传失败了，请稍后再试哦");
        },

        //提交
        onSubmit(){
            // console.log("数据提交 file_num:"+this.fileList.length+"\t"+JSON.stringify(this.fileList));
            if(API.isEmtry(this.form_data.is_send_channel)){
                this.$message.error("请设置是否直接向通道报备");
                return;
            }else{
                //是否直接向通道报备 0：是 1：不是
                if(this.form_data.is_send_channel == 1){
                    if(API.isEmtry(this.form_data.signword_id)){
                        this.$message.error("请填写签名id");
                        return;
                    }
                }
            }

            if(API.isEmtry(this.form_data.province)){
                this.$message.error("请选择通道归属省份");
                return;
            }
            if(API.isEmtry(this.form_data.city)){
                this.$message.error("请选择通道归属地市");
                return;
            }
            if(API.isEmtry(this.form_data.service_code)){
                this.$message.error("请输入服务代码");
                return;
            }
            if(API.isEmtry(this.form_data.signword)){
                this.$message.error("请输入报备的签名内容");
                return;
            }
            if(API.isEmtry(this.form_data.user_company_id)){
                this.$message.error("请从公司列表中选择实际发送的企业");
                return;
            }
            if(API.isEmtry(this.form_data.rcs_industry)){
                this.$message.error("请选择信息安全行业属性，至多只能选五个,最少一个");
                return;
            }
            if(API.isEmtry(this.form_data.customer_type)){
                this.$message.error("请选择客户分类");
                return;
            }
            if(this.fileList.length == 0){
                API.MediaSignServlet({
                    param: "addMediaSignSH",
                    is_send_channel:this.form_data.is_send_channel,
                    signword_id:this.form_data.signword_id,
                    channel_id:this.channel_id,
                    province:this.form_data.province,
                    city:this.form_data.city,
                    service_code:this.form_data.service_code,
                    signword:this.form_data.signword,
                    user_company_id:this.form_data.user_company_id,
                    rcs_industry:this.form_data.rcs_industry.join(","),
                    industry:this.form_data.industry,
                    customer_type:this.form_data.customer_type,
                }).then((res) => {
                    //console.log(res);
                    if (res.recode === 0) {
                        this.$message({
                            message: "恭喜您，提交成功",
                            type: "success",
                            offset: 280
                        });
                        this.dialog_visible = false;//弹出框是否可见   false:不可见
                        this.$emit("add_sign", "");
                    }
                });
            }else{
                this.$refs.uploadFile.submit();
            }
        },
        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },

    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}
/*form表单 间隔*/
.el-form-item {
  margin-bottom: 10px;
}
</style>